// Switch video poster to actual iframe playing video

const videoPlayer = {
  init() {
    this.bind();
  },
  bind() {
    const link = document.querySelector('#player_switcher');

    link.addEventListener('click', e => {
      e.preventDefault();

      const videoplayer = document.querySelector('.video-player-section');

      videoplayer.classList.toggle('video-small');

      /*
      const wrapper = e.target.parentNode;
      const iframe = wrapper.querySelector('.js-video-iframe');
      const videoSrc = iframe.dataset.src;
      iframe.setAttribute('src', videoSrc);
      wrapper.classList.add('video-wrapper-active');
      */
    });
  },
};

export default videoPlayer;
