import talks from './modules/talks';
import videoPlayer from './modules/videoplayer';
import 'bootstrap/js/src/util';
import 'bootstrap/js/src/modal';

// Require jQuery
const jquery = require('jquery');
$ = window.$ = window.jQuery = jquery;

document.addEventListener('DOMContentLoaded', () => {
  talks.init();
  videoPlayer.init();
});
